var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "", size: "lg" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _c("div", { staticClass: "float-right" }, [
            _c("i", {
              staticClass: "bx bx-x font-size-20",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.closeMode()
                },
              },
            }),
          ]),
          _c("h5", {}, [
            _vm._v(_vm._s(_vm.$t("datafileReport.certificateInfoModal.title"))),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("datafileReport.certificateInfoModal.description")
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("strong", [_vm._v("Standard")]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("datafileReport.certificateInfoModal.level1")) +
                " "
            ),
            _c("ul", [
              _c("li", [_vm._v("1 x Time-Score ")]),
              _c("li", [_vm._v("2 x Open-Ended-Score")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("strong", [_vm._v("Standard Plus")]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("datafileReport.certificateInfoModal.level2")) +
                " "
            ),
            _c("ul", [
              _c("li", [_vm._v("1 x Time-Score ")]),
              _c("li", [_vm._v("2 x Open-Ended-Score ")]),
              _c("li", [_vm._v("1 x Item-Battery-Score ")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("strong", [_vm._v("Extended")]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("datafileReport.certificateInfoModal.level3")) +
                " "
            ),
            _c("ul", [
              _c("li", [_vm._v("1 x Time-Score ")]),
              _c("li", [_vm._v("2 x Open-Ended-Score ")]),
              _c("li", [_vm._v("2 x Item-Battery-Score ")]),
              _c("li", [_vm._v("1 x Prediction-Score ")]),
              _c("li", [_vm._v("1 x Certainty-Score ")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12" }, [
          _c("hr"),
          _c("p", [
            _c("ul", [
              _c("li", [
                _c("strong", [_vm._v("Time-Score")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("datafileReport.scoreThresholdInfoModal.timeScore")
                    )
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Open-Ended-Score")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileReport.scoreThresholdInfoModal.openEndedScore"
                      )
                    )
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Item-Battery-Score")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileReport.scoreThresholdInfoModal.itemBatteryScore"
                      )
                    )
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Prediction-Score")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileReport.scoreThresholdInfoModal.predictionScore"
                      )
                    )
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Certainty-Score")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "datafileReport.scoreThresholdInfoModal.certaintyScore"
                      )
                    )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }