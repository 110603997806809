<script>
/* eslint-disable no-unused-vars */
import DatafileReportService from "@/api/services/datafileReport.service.js";
import DataCleaningMethods from "@/shared/enums/dataCleaningMethods.js";
import { GLOBALS } from "@/shared/util/globals.js";

export default {
  props: {
    datafileId: String,
    isDisplayQualityScoreWidgets: Boolean,
    algorithmsInDatafile: Object
  },
  data() {
    return {
      dataForTable: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "rScore",
      sortDesc: true,
      dbDatafile: {},
      dataCleaningMethods: DataCleaningMethods
    };
  },
  computed: {
    fieldsForTable() {
      let fields = [
        {
          key: "recordId",
          label: this.$t("datafileResultCSTab.dataRecordTable.recordID"),
          sortable: false
        },
        {
          key: "isIncluded",
          label: this.$t("datafileReport.respondentTable.include"),
          sortable: true
        },
        {
          key: "collectingTime",
          label: this.$t("datafileReport.respondentTable.collectingTime"),
          sortable: false
        },
        {
          key: "cleaningTime",
          label: this.$t("datafileReport.respondentTable.cleaningTime"),
          sortable: false
        },
        {
          key: "rScore",
          label: "Redem-Score",
          sortable: true
        },
        {
          key: "oeScore",
          label: "Open-Ended-Score",
          sortable: true
        },
        {
          key: "timeScore",
          label: "Time-Score",
          sortable: true
        },
        {
          key: "ibScore",
          label: "Grid-Question-Score",
          sortable: true
        },
        {
          key: "pScore",
          label: "Prediction-Score",
          sortable: true
        },
        {
          key: "cScore",
          label: "Certainty-Score",
          sortable: true
        },
        {
          key: "iScore",
          label: "Information-Score",
          sortable: true
        },
        {
          key: "weight",
          label: "Weight",
          sortable: true
        },
        {
          key: "cleaningMethod",
          label: this.$t("datafileReport.respondentTable.cleaningMethod"),
          sortable: true
        }
      ];
      if (!this.isDisplayQualityScoreWidgets) {
        const qScoreHeaders = [
          "pScore",
          "cScore",
          "iScore",
          "timeScore",
          "ibScore",
          "oeScore"
        ];
        fields = fields.filter(o => !qScoreHeaders.includes(o.key));
      }
      const qScoreHeadersToRemove = [];
      if (!this.algorithmsInDatafile.PS) qScoreHeadersToRemove.push("pScore");
      if (!this.algorithmsInDatafile.CS) qScoreHeadersToRemove.push("cScore");
      if (!this.algorithmsInDatafile.TS)
        qScoreHeadersToRemove.push("timeScore");
      if (!this.algorithmsInDatafile.IS) qScoreHeadersToRemove.push("iScore");
      if (!this.algorithmsInDatafile.IBS) qScoreHeadersToRemove.push("ibScore");
      if (!this.algorithmsInDatafile.OES) qScoreHeadersToRemove.push("oeScore");
      if (!this.dbDatafile.isLiveCleaning) {
        qScoreHeadersToRemove.push("collectingTime", "cleaningTime");
      }
      if (!this.dbDatafile.isCleaned) {
        qScoreHeadersToRemove.push("cleaningTime", "cleaningMethod");
      }
      if (!this.dbDatafile.isWeighted) {
        qScoreHeadersToRemove.push("weight");
      }
      fields = fields.filter(o => !qScoreHeadersToRemove.includes(o.key));
      return fields;
    },
    rows() {
      return this.dataForTable.length;
    }
  },
  async mounted() {
    await this.fetchTableData();
  },
  methods: {
    async fetchTableData() {
      this.dbDatafile = await DatafileReportService.getReportDatafile(
        this.datafileId,
        GLOBALS.reportPassword
      );
      this.dataForTable = await DatafileReportService.getReportRecordTable(
        this.datafileId,
        true,
        GLOBALS.reportPassword
      );
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        {{ $t("datafileReport.respondentTable.title") }}
      </div>
      <div>
        <span class="mr-2">
          <i class="bx bx-check-circle text-success font-size-20 align-bottom">
          </i>
          {{ $t("datafileReport.respondentTable.legend2") }}
        </span>
        <span class="mr-2">
          <i class="bx bx-x-circle text-danger font-size-20 align-bottom"> </i>
          {{ $t("datafileReport.respondentTable.legend3") }}
        </span>
        <span>
          <i
            class="bx bx-minus-circle text-warning font-size-20 align-bottom"
          ></i>
          {{ $t("datafileReport.respondentTable.legend1") }}
        </span>
      </div>

      <div class="card-text">
        <div class="row mt-4">
          <div class="col-md-12 text-center">
            <div class="table-responsive mb-0">
              <b-table
                :items="dataForTable"
                :fields="fieldsForTable"
                :filter="filter"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template v-slot:cell(isIncluded)="data">
                  <i
                    v-if="!dbDatafile.isCleaned"
                    class="bx bx-minus-circle text-warning font-size-20"
                  ></i>
                  <i
                    class="bx bx-x-circle text-danger font-size-20"
                    v-if="dbDatafile.isCleaned && !data.value"
                  >
                  </i>
                  <i
                    class="bx bx-check-circle text-success font-size-20"
                    v-if="dbDatafile.isCleaned && data.value"
                  >
                  </i>
                </template>
                <template v-slot:cell(rScore)="data">
                  <span>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(pScore)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(cScore)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(iScore)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(timeScore)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(ibScore)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(oeScore)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(weight)="data">
                  <span v-if="data.value === -999">
                    {{
                      $t("datafileResultGeneral.dataRecordTable.notAvailable")
                    }}
                  </span>
                  <span v-else>
                    {{
                      data.value.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </template>
                <template v-slot:cell(cleaningMethod)="data">
                  <span v-if="data.value === dataCleaningMethods.QUOTA_TARGET">
                    {{
                      $t("datafileReport.respondentTable.excluedReason.quota")
                    }}
                  </span>
                  <span v-if="data.value === dataCleaningMethods.MANUAL">
                    {{
                      $t("datafileReport.respondentTable.excluedReason.manual")
                    }}
                  </span>
                  <span
                    v-if="data.value === dataCleaningMethods.SCORE_THRESHOLD"
                  >
                    {{
                      $t(
                        "datafileReport.respondentTable.excluedReason.scoreThreshold"
                      )
                    }}
                  </span>
                  <span v-if="data.value === dataCleaningMethods.TARGET_SAMPLE">
                    {{
                      $t(
                        "datafileReport.respondentTable.excluedReason.targetSample"
                      )
                    }}
                  </span>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
