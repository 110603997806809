<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
import { DistributionHistogramChart } from "@/redem/datafiles/result-charts.js";
import { GLOBALS } from "@/shared/util/globals.js";

export default {
  props: {
    datafileId: String
  },
  data() {
    return {
      distributionChartOptions: null,
      chartDataForRawDistributionChart: {
        name: "Number of Datarecords",
        data: []
      },
      chartDataForCleanedDistributionChart: {
        name: "Number of Datarecords",
        data: []
      }
    };
  },
  async created() {
    await this.fetchChartData();
    this.distributionChartOptions = new DistributionHistogramChart();
  },
  methods: {
    async fetchChartData() {
      const distributionData = await DatafileReportService.getReportDistributionData(
        this.datafileId,
        GLOBALS.reportPassword
      );
      this.chartDataForRawDistributionChart.data = distributionData[0];
      this.chartDataForCleanedDistributionChart.data = distributionData[1];
    }
  }
};
</script>

<template>
  <div class="card" style="height: 450px;">
    <div class="card-body">
      <div class="card-title mb-3">
        {{ $t("datafileReport.distributionWidget.title") }}
      </div>
      <div class="card-text">
        {{ $t("datafileReport.distributionWidget.description") }}
      </div>
      <!-- Chart Tabs -->
      <div class="card-text mt-4">
        <div class="row">
          <div class="col-12">
            <b-tabs>
              <!-- Clean Tab -->
              <b-tab active lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.cleaned") }}
                </template>
                <div>
                  <apexchart
                    ref="distributionChart"
                    class="apex-charts mt-3"
                    dir="ltr"
                    type="bar"
                    height="250"
                    :series="[chartDataForCleanedDistributionChart]"
                    :options="distributionChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
              <!-- Raw Tab -->
              <b-tab lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.raw") }}
                </template>
                <div>
                  <apexchart
                    ref="distributionChart"
                    class="apex-charts mt-3"
                    dir="ltr"
                    type="bar"
                    height="250"
                    :series="[chartDataForRawDistributionChart]"
                    :options="distributionChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
