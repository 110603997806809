<script>
import ReportTopbar from "@/shared/components/report-topbar";

export default {
  components: {
    ReportTopbar
  },
  methods: {}
};
</script>

<template>
  <div style="overflow-x: hidden;">
    <div id="layout-wrapper">
      <ReportTopbar />

      <div class="">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
