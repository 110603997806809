<script>
import DatafileReportService from "@/api/services/datafileReport.service.js";
import { TrafficLightChart } from "@/redem/datafiles/result-charts.js";
import { GLOBALS } from "@/shared/util/globals.js";

export default {
  props: {
    datafileId: String
  },
  data() {
    return {
      trafficLightChartOptions: null,
      chartDataForRawTrafficLightChart: [20, 50, 40, 10, 5],
      chartDataForCleanedTrafficLightChart: [0, 0, 15, 80, 5]
    };
  },
  async created() {
    await this.fetchChartData();
    this.trafficLightChartOptions = new TrafficLightChart();
  },
  methods: {
    async fetchChartData() {
      const trafficLightData = await DatafileReportService.getReportTrafficLightData(
        this.datafileId,
        GLOBALS.reportPassword
      );
      this.chartDataForRawTrafficLightChart = [
        trafficLightData[0].Red,
        trafficLightData[0].Yellow,
        trafficLightData[0].LightGreen,
        trafficLightData[0].Green,
        trafficLightData[0].Grey
      ];
      this.chartDataForCleanedTrafficLightChart = [
        trafficLightData[1].Red,
        trafficLightData[1].Yellow,
        trafficLightData[1].LightGreen,
        trafficLightData[1].Green,
        trafficLightData[1].Grey
      ];
    }
  }
};
</script>

<template>
  <div class="card" style="height: 450px;">
    <div class="card-body">
      <div class="card-title mb-3 ">
        {{ $t("datafileReport.trafficLightWidget.title") }}
      </div>
      <div class="card-text">
        {{ $t("datafileReport.trafficLightWidget.description") }}
      </div>
      <!-- Chart Tabs -->
      <div class="card-text mt-4">
        <div class="row">
          <div class="col-12">
            <b-tabs>
              <!-- Clean Tab -->
              <b-tab active lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.cleaned") }}
                </template>
                <div>
                  <apexchart
                    ref="trafficLightChart"
                    class="apex-charts mt-3"
                    height="280"
                    type="donut"
                    dir="ltr"
                    :series="chartDataForCleanedTrafficLightChart"
                    :options="trafficLightChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
              <!-- Raw Tab -->
              <b-tab lazy>
                <template v-slot:title>
                  {{ $t("datafileReport.raw") }}
                </template>
                <div>
                  <apexchart
                    ref="trafficLightChart"
                    class="apex-charts mt-3"
                    height="280"
                    type="donut"
                    dir="ltr"
                    :series="chartDataForRawTrafficLightChart"
                    :options="trafficLightChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
