<script>
export default {
  props: {
    certificateCode: String,
    certificateLevel: String,
    certificateImageURL: String
  },
  components: {},
  data() {
    return {
      cardHeight: "350px"
    };
  },
  mounted() {
    window.addEventListener("resize", this.setCardHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.setCardHeight);
  },
  methods: {
    clickInfQualityLevels() {
      this.$emit("displayCertificationLevelModal");
    },
    setCardHeight() {
      if (window.screen.width > 1000) {
        this.cardHeight = "350px";
      }

      if (window.screen.width < 999) {
        this.cardHeight = null;
      }
    }
  }
};
</script>

<template>
  <div class="card" :style="{ height: this.cardHeight }">
    <div class="card-body">
      <!-- Title -->
      <h5 class="card-title">
        {{ $t("datafileReport.certificateWidget.title") }}
        <i
          class="bx bxs-info-circle ml-2 font-size-16 text-info bx-tada"
          style="cursor: pointer"
          @click="clickInfQualityLevels()"
        ></i>
        <a :href="certificateImageURL" download>
          <b-button class="float-right" size="sm" variant="light"
            ><i class="bx bxs-download"></i>
          </b-button>
        </a>
      </h5>

      <!-- Certificate Level -->
      <div class="badge badge-gold font-size-12">
        {{ certificateLevel }}
      </div>

      <!-- Certificate  -->
      <div class="row mt-4">
        <div class="col-12 text-center">
          <img
            src="@/assets/images/redem-certification.png"
            alt
            class="img-fluid"
            style="width: 150px"
          />
        </div>
        <div class="col-12 mt-3 text-center">
          <h5>{{ certificateCode }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
