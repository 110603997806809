<script>
import i18n from "@/i18n";

export default {
  components: {},
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null
    };
  },
  created() {
    const locale = window.localStorage.getItem("locale");
    if (locale) {
      i18n.locale = locale;
    } else {
      //Get language from browser
      let browserLanguage =
        navigator.language || navigator.userLanguage || navigator.languages[0];
      browserLanguage = browserLanguage.slice(0, 2);
      // fallback
      if (browserLanguage !== "en" && browserLanguage !== "de")
        browserLanguage = "de";
      window.localStorage.setItem("locale", browserLanguage);
      i18n.locale = browserLanguage;
    }
    this.value = this.languages.find(x => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  computed: {
    localeFormat() {
      return "de-DE";
    }
  },
  mounted() {},
  methods: {
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="row navbar-header">
      <div class="col-6">
        <!-- LOGO -->
        <div class="pl-4">
          <img src="@/assets/images/Redem-Logo.png" alt height="30" />
        </div>
      </div>

      <div class="col-6 text-right">
        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
